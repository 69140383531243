import './App.css';
import { loadStripe } from '@stripe/stripe-js';
import Wrapper from './components/Wrapper'
import { Elements } from '@stripe/react-stripe-js';
import React, { useState, useRef } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
// import { Link } from 'react-router-dom'
import Footer from './components/Footer'

const stripePromise = loadStripe('pk_live_51Q0mpPAvc7OJE1HgrXR7hUfqHQT6vYzKOFhUyY5SOnGmCWzqyDNCNL4ZazSdmLlW2ypzidtPFySZ1P0CiLbCx4aK002N51ezBu');

function Main() {
  const [prices] = useState({ 30: 14, 365: 144, 730: 240, 1095: 320 });
  const [name, setName] = useState('');
  const [isAutoPay, setIsAutoPay] = useState(true);
  const [email, setEmail] = useState(null);
  const [duration, setDuration] = useState(30);
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [formSubmittedAutoPay, setFormSubmittedAutoPay] = useState(false);
  const isMobile = useMediaQuery('(max-width: 668px)');
  const priceData = {
    30: 'price_1Q0nKkAvc7OJE1HgnItk70ko',
    365: 'price_1Q0nLIAvc7OJE1HgEv3rHARq',
    730: 'price_1Q0nMSAvc7OJE1HgtkmQqjAN',
    1095: 'price_1Q0nN2Avc7OJE1HgYFajWIaO',
  };

  const svgRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getSvgPosition = () => {
    if (svgRef.current) {
      const { x, y, width } = svgRef.current.getBoundingClientRect();
      return { top: y - 20, left: isMobile?(x-width-50):(x - width + 50) };
    }
    return { top: 0, left: 0 };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {

      const agent = navigator.userAgent;

      if (isAutoPay===false || isAutoPay==='false') {
        await fetch('/.netlify/functions/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            browserAgent: agent,
            amount: prices[duration],
            name: name,
            email: email,
            duration: duration,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setClientSecret(data.clientSecret);
          });
      }
      else {
        setFormSubmittedAutoPay(true)
      }

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1c53de'
    }
  };

  const options = isAutoPay === false ? { clientSecret, appearance, business: "CheapCC" } : { mode: 'setup', currency: 'usd', appearance, business: "CheapCC" }

  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src='/images/logo.svg' className='logo' alt='cheapcc-logo' />
      </div>
      <div className='main'>
        {isHovered === true && (<div className='question-div' style={{ position: 'fixed', ...getSvgPosition(), zIndex: 999, width: '230px', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>If disabled, your enrollment will be over at the end of the first period. If enabled, renewal will be automatic.<br></br>To cancel auto-renew, simply contact us.</div>)}
        <div className='main-main'>
          <div className='main-main-main'>
            <form onSubmit={handleSubmit} className={`inputs ${!clientSecret && formSubmittedAutoPay === false ? "shown" : ''}`}>
              {!clientSecret && formSubmittedAutoPay === false && <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
                <h1 style={{ marginBottom: 0, textAlign: 'left' }}>Enroll to our design course</h1>
                <p>Art School Movement (ASM) is a fully online graphic design course. To be able to train correctly, an edcuative Adobe subscription is added to your current Adobe account. Behind you are experienced senior designers who can answer to all your questions.</p>
                <div className='input-container'>
                  <div className='input-div'>
                    <label>Full name:</label>
                    <div className='input-wrapper' style={{ maxWidth: 'calc(100% - 40px)', minWidth: 280 }} ><input style={{ width:'-webkit-fill-available' }} value={name} spellCheck={false} required onChange={(e) => (setName(e.target.value))} placeholder='John Smith' type='name' /></div>
                  </div>
                  <div className='input-div'>
                    <label style={{ textAlign: 'left', gap:10 }}>E-mail address:</label>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div className='input-wrapper' style={{ maxWidth: 'calc(100% - 40px)', minWidth: 280 }} ><input style={{ width:'-webkit-fill-available' }} value={email} spellCheck={false} required onChange={(e) => (setEmail(e.target.value))} placeholder='john.smith@gmail.com' type='email' /></div>
                    </div>
                  </div>
                  <div className='input-div'>
                    <label>Duration of your enrollment:</label>
                    <select value={duration} onChange={(e) => (setDuration(parseFloat(e.target.value)))}>
                      <option value={30}>1 month</option>
                      <option value={365}>1 year</option>
                      <option value={730}>2 years</option>
                      <option value={1095}>3 years</option>
                    </select>
                  </div>
                  <div className='input-div'>
                    <label style={{ textAlign: 'left', display: 'flex', alignItems: 'center', gap: 10 }}>Enable auto renew:<svg ref={svgRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer' }} fill="black" height="20" viewBox="0 0 15 15" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="#black"><path d="m6.74984 10.5c0 .4142.33579.75.75.75.41422 0 .75-.3358.75-.75s-.33578-.74999-.75-.74999c-.41421 0-.75.33579-.75.74999z" /><path d="m8.94995 6.25001c0-.67789-.58507-1.325-1.45-1.325-.86492 0-1.45.64711-1.45 1.325 0 .30376-.24624.55-.55.55s-.55-.24624-.55-.55c0-1.32211 1.11493-2.425 2.55-2.425 1.43508 0 2.55005 1.10289 2.55005 2.425 0 1.10382-.73004 1.64038-1.26449 1.96506-.10264.06235-.19141.1138-.26993.1593-.15058.08727-.26359.15276-.36509.23184-.06564.05115-.09133.08341-.10001.0971l-.00054.00087c-.00224.30184-.24762.54583-.54999.54583-.30375 0-.55-.24624-.55-.55 0-.46802.28211-.77268.52446-.96151.16338-.1273.38078-.25355.56398-.35994.0641-.03723.12404-.07204.176-.10361.46555-.28282.73556-.53376.73556-1.02494z" /><path clipRule="evenodd" d="m14 7.5c0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5zm-1 0c0 3.0376-2.4624 5.5-5.5 5.5-3.03757 0-5.5-2.4624-5.5-5.5 0-3.03757 2.46243-5.5 5.5-5.5 3.0376 0 5.5 2.46243 5.5 5.5z" fillRule="evenodd" /></g></svg></label>
                    <select value={isAutoPay} onChange={(e) => (setIsAutoPay(e.target.value==='true' || e.target.value===true ))}>
                      <option value={true}>Yes, please</option>
                      <option value={false}>No, thanks</option>
                    </select>
                  </div>
                </div>
                <div>
                  <h1 style={{ textWrap: 'nowrap', width: 'fit-content' }}><span style={{ fontSize: 24 }}>€</span> {prices[duration]}.00 {duration !== 30 && <span style={{ fontSize: 14, fontWeight: 400 }}>(= € {duration === 30 ? 14 : (duration === 365 ? 12 : (duration === 730 ? 10 : (duration === 1095 ? 9 : '???')))}.00 /month)</span>}</h1>
                </div>
                <div className='pay-button-div'>
                  <button disabled={(!email || !name)} type="submit"><span id="button-text">
                    {!clientSecret && formSubmittedAutoPay === false && isLoading === true ? <div className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={201} height={201} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '21.5', height: '21.5', paddingLeft: 11, paddingRight: 11, paddingTop: -2, paddingBottom: -2 }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="10" stroke="#ffffff" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div> : "Enroll"}</span></button>
                  {/* <p style={{ margin: 0, color:'red' }}>Payment processor unavailable. Contact us.</p> */}
                </div>
                {/* <div>
              <p>Once you've filled this form and paid, you'll receive a "team invitation" email from Adobe, inviting you to join a school team subscription.</p>
              <p>Simply accept it, and you now have access to the whole Creative Cloud suite, with <b>all Adobe applications</b>.</p>
            </div> */}
              </div>}
            </form>
            <div className={`payment-container ${(isPaymentDone === false && clientSecret) || (formSubmittedAutoPay === true && isPaymentDone === false) ? "shown" : ''}`}>
              {(isPaymentDone === false && clientSecret && isAutoPay===false) && <Elements stripe={stripePromise} options={options}>
                <button onClick={() => (setClientSecret(null))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
                <Wrapper priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} clientSecret={clientSecret} />
              </Elements>}
              {(isPaymentDone === false && isAutoPay === true && formSubmittedAutoPay === true) && <Elements stripe={stripePromise} options={options}>
                <button onClick={() => (setFormSubmittedAutoPay(false))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
                <Wrapper priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} />
              </Elements>}
            </div>
            <div className={`payment-container-done ${isPaymentDone === true && isLoading === false ? "shown" : ''}`}><div className='success-div'><div class="animation-ctn">
              <div class="icon icon--order-success svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
                  <g fill="none" stroke="#22AE73" stroke-width="2">
                    <circle cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{ strokeDasharray: '100px, 100px', strokeDashOffset: 200 }} />
                  </g>
                </svg>
              </div>
            </div>{isPaymentDone===true && isLoading===false && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{isPaymentDone===true && isLoading===false && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{isPaymentDone===true && isLoading===false && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{isPaymentDone===true && isLoading===false && <h3 style={{ textAlign: 'center', marginTop: 20 }}>problem@cheapcc.net</h3>}</div></div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Main;
